const dateNow = () => {
  const date = new Date()

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }
  return date.toLocaleDateString('pt-BR', options)
}

const dateTimeSqlToDateTimeBR = (value) => {
  if (value !== null && value !== '') {
    const date = new Date(value)
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }
    return date.toLocaleDateString('pt-BR', options)
  }
  return value
}

const dateTimeSqlToDateBR = (value) => {
  if (value !== null && value !== '') {
    const date = new Date(`${value} 00:00:00`)
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }
    return date.toLocaleDateString('pt-BR', options)
  }
  return value
}

const dateSqlToDateBR = (value) => {
  if (value !== null && value !== '') {
    const date = new Date(`${value} 00:00:00`)
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }
    return date.toLocaleDateString('pt-BR', options)
  }
  return value
}

const formatarDataLancamento = (item) => {
  const tipo = parseInt(item.tipo, 10)
  switch (tipo) {
    case 1:
      return `<h6 class="d-none d-sm-inline-block text-bold">${dateSqlToDateBR(
        item.data_vencimento,
      )}</h6> <br> Data do vencimento`
    case 2:
      return `<h6 class="d-none d-sm-inline-block text-bold">${dateSqlToDateBR(
        item.data_vencimento,
      )}</h6> <br> Data do vencimento`
    case 3:
      return `<h6 class="d-none d-sm-inline-block text-bold">${dateSqlToDateBR(
        item.data_pagamento,
      )}</h6> <br> Data do pagamento`
    case 4:
      return `<h6 class="d-none d-sm-inline-block text-bold">${dateSqlToDateBR(
        item.data_pagamento,
      )}</h6> <br> Data do pagamento`
    default:
      return `<h6 class="d-none d-sm-inline-block text-bold">${dateSqlToDateBR(
        item.data_vencimento,
      )}</h6> <br> Data do vencimento`
  }
}

const transformDateInObject = (value) => {
  return value.map((dateString) => {
    const dateObj = new Date(`${dateString} 00:00:00`)
    return [
      dateObj.getDate(),
      dateObj.getMonth() + 1,
      dateObj.getFullYear(),
    ].join('/')
  })
}

export {
  dateNow,
  dateTimeSqlToDateTimeBR,
  dateTimeSqlToDateBR,
  dateSqlToDateBR,
  transformDateInObject,
  formatarDataLancamento,
}
