<template>
  <b-modal
    id="modalCreateEditModeloPeca"
    size="xl"
    :title="textModal"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="save">
      <div class="col-lg-12">
        <b-tabs
          justified
          nav-class="nav-tabs-custom"
          content-class="p-3 text-muted"
        >
          <b-tab active>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block">Dados</span>
            </template>
            <!-- NOME DO MODELO DE PEÇA -->
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Nome da peça: <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.modeloPeca.titulo.$error }"
                  placeholder="Nome do modelo de Peça"
                  v-model="$v.modeloPeca.titulo.$model"
                />
                <span
                  v-if="!$v.modeloPeca.titulo.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
                <span
                  v-if="!$v.modeloPeca.titulo.minLength"
                  class="invalid-feedback"
                >
                  O nome da peça deve possuir pelo menos 3 caracteres
                </span>
              </div>
            </div>
            <!-- CATEGORIA -->
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Categoria: <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  :class="{ 'is-invalid': $v.modeloPeca.categoria_id.$error }"
                  v-model="$v.modeloPeca.categoria_id.$model"
                >
                  <option value="">Selecione uma categoria</option>
                  <option
                    v-for="categoria in categorias"
                    :key="categoria.id"
                    :value="categoria.id"
                    :title="categoria.descricao"
                  >
                    {{ categoria.descricao }}
                  </option>
                </select>
                <span
                  v-if="!$v.modeloPeca.categoria_id.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
            </div>

            <!-- TEMPO ESTIMADO DE PRODUÇÃO -->
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>Tempo estimado: </label>
                <input
                  type="time"
                  class="form-control"
                  placeholder="Tempo estimado de produção completa da peça"
                  v-model="modeloPeca.tempo_estimado"
                />
              </div>

              <!-- VALOR DA PEÇA -->
              <div class="form-group col-sm-6">
                <label>Valor da peça: </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Valor cobrado pela peça"
                  v-model="modeloPeca.valor_agencia"
                />
              </div>
            </div>

            <!-- BRIEFING DA PEÇA -->
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Briefing:</label>
                <ckeditor
                  v-model="modeloPeca.briefing"
                  :editor="editor"
                ></ckeditor>
              </div>
            </div>
            <div v-if="modeloPecaId" class="form-row">
              <div class="form-group col-sm-6">
                <p>Status: <span class="text-danger">*</span></p>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="statusInativo"
                    value="0"
                    v-model="modeloPeca.status"
                  />
                  <label class="form-check-label" for="statusInativo">
                    Inativo
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="statusAtivo"
                    value="1"
                    v-model="modeloPeca.status"
                  />
                  <label class="form-check-label" for="statusAtivo">
                    Ativo
                  </label>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-user"></i>
              </span>
              <span class="d-none d-sm-inline-block">Workflow</span>
            </template>
            <div role="tablist">
              <draggable class="list-group" group="tasks" :list="etapas">
                <div
                  v-for="(etapa, index) in etapas"
                  :key="index"
                  class="card task-box"
                >
                  <div
                    class="progress progress-sm animated-progess"
                    style="height: 3px"
                  >
                    <div
                      v-bind:class="{
                        'bg-info': etapas[index].modelo_etapa_peca_id == 1,
                        'bg-primary': etapas[index].modelo_etapa_peca_id == 2,
                        'bg-warning': etapas[index].modelo_etapa_peca_id == 3,
                      }"
                      class="progress-bar"
                      role="progressbar"
                      style="width: 100%"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      variant="success"
                    ></div>
                  </div>
                  <b-card no-body class="mb-1 shadow-none">
                    <b-card-header header-tag="header" role="tab">
                      <div class="form-row">
                        <div class="form-group col-sm-1">
                          <br />
                          <a
                            v-b-toggle="'accordion-' + index"
                            class="btn btn-lg btn-rounded"
                            v-bind:class="{
                              'btn-outline-info':
                                etapas[index].modelo_etapa_peca_id == 1,
                              'btn-outline-primary':
                                etapas[index].modelo_etapa_peca_id == 2,
                              'btn-outline-warning':
                                etapas[index].modelo_etapa_peca_id == 3,
                            }"
                            href="javascript: void(0);"
                            >{{ index + 1 }}</a
                          >
                        </div>
                        <div class="form-group col-sm-6">
                          <label>Título: </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Título da etapa"
                            :name="'etapas[' + index + '].[titulo]'"
                            :id="'etapas[' + index + ']'"
                            v-model="etapas[index].titulo"
                          />
                        </div>
                        <div class="form-group col-sm-4">
                          <label>Tipo: </label>
                          <select
                            class="form-control"
                            v-model="etapas[index].modelo_etapa_peca_id"
                          >
                            <option value="">Selecione um tipo de etapa</option>
                            <option
                              v-for="tipo in modelosEtapasPecas"
                              :key="tipo.id"
                              :value="tipo.id"
                              :title="tipo.descricao"
                            >
                              {{ tipo.descricao }}
                            </option>
                          </select>
                        </div>
                        <div class="form-group col-sm-1">
                          <br />
                          <button
                            type="button"
                            @click="doDelete(index)"
                            class="btn btn-lg btn-outline-danger"
                            title="Adicionar item"
                          >
                            <i class="bx ri-delete-bin-line"></i>
                          </button>
                        </div>
                      </div>
                    </b-card-header>
                    <b-collapse
                      :id="'accordion-' + index"
                      visible
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body
                        v-if="etapas[index].modelo_etapa_peca_id != 3"
                      >
                        <div class="form-row">
                          <div class="form-group col-sm-1"></div>
                          <div class="form-group col-sm-4">
                            <label>Responsável: </label>
                            <select
                              class="form-control"
                              v-model="etapas[index].user_id"
                            >
                              <option value="">
                                Selecione um responsável padrão
                              </option>
                              <option
                                v-for="user in users"
                                :key="user.id"
                                :value="user.id"
                                :title="user.name"
                              >
                                {{ user.name }}
                              </option>
                            </select>
                          </div>
                          <div class="form-group col-sm-3">
                            <label
                              >Dias úteis antes do deadline da peça:
                            </label>
                            <input
                              type="number"
                              class="form-control"
                              placeholder="Título da etapa"
                              :name="
                                'etapas[' + index + '].[data_final_padrão]'
                              "
                              :id="'etapas[' + index + ']'"
                              v-model="etapas[index].data_final_padrão"
                            />
                          </div>
                          <div class="form-group col-sm-3">
                            <label>Tempo Estimado: </label>
                            <input
                              type="time"
                              class="form-control"
                              placeholder="Tempos estimado de produção"
                              :name="'etapas[' + index + '].[tempo_estimado]'"
                              :id="'etapas[' + index + ']'"
                              v-model="etapas[index].tempo_estimado"
                            />
                          </div>
                          <div class="form-group col-sm-1"></div>
                        </div>
                        <b-card-text></b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </draggable>
              <div>
                <button
                  type="button"
                  @click="addEtapa()"
                  class="btn btn-primary"
                  title="Adicionar rasgadinha"
                >
                  <i class="bx ri-add-line"></i> Nova Etapa
                </button>
              </div>
            </div>
          </b-tab>

          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-user"></i>
              </span>
              <span class="d-none d-sm-inline-block">Checklist</span>
            </template>
            <div role="tablist">
              <draggable class="list-group" group="tasks" :list="checklist">
                <div
                  v-for="(item, index) in checklist"
                  :key="index"
                  class="card task-box"
                >
                  <div
                    class="progress progress-sm animated-progess"
                    style="height: 3px"
                  >
                    <div
                      class="progress-bar bg-info"
                      role="progressbar"
                      style="width: 100%"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      variant="success"
                    ></div>
                  </div>
                  <b-card no-body class="mb-1 shadow-none">
                    <b-card-header header-tag="header" role="tab">
                      <div class="form-row">
                        <div class="form-group col-sm-1">
                          <br />
                          <a
                            v-b-toggle="'accordion-' + index"
                            class="btn btn-lg btn-outline-info btn-rounded"
                            href="javascript: void(0);"
                            >{{ index + 1 }}</a
                          >
                        </div>

                        <div class="form-group col-sm-10">
                          <label>Descrição do item:</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Digite a descrição do seu item"
                            :name="'checklist[' + index + '].[descricao_item]'"
                            :id="'checklist[' + index + ']'"
                            v-model="checklist[index].descricao_item"
                            autocomplete="off"
                          />
                        </div>
                        <div class="form-group col-sm-1">
                          <br />
                          <button
                            type="button"
                            @click="deleteRow(index)"
                            class="btn btn-lg btn-outline-danger"
                            title="Adicionar item"
                          >
                            <i class="bx ri-delete-bin-line"></i>
                          </button>
                        </div>
                      </div>
                    </b-card-header>
                    <b-collapse
                      :id="'accordion-' + index"
                      visible
                      accordion="my-accordion"
                      role="tabpanel"
                    ></b-collapse>
                    <b-collapse
                      :id="'accordion-' + index"
                      visible
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                    </b-collapse>
                  </b-card>
                </div>
              </draggable>
              <div>
                <button
                  type="button"
                  @click="addRow()"
                  class="btn btn-primary"
                  title="Adicionar rasgadinha"
                >
                  <i class="bx ri-add-line"></i> Adicionar item
                </button>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>

      <hr />
      <div class="modal-footer-custom">
        <button
          type="button"
          class="btn btn-secondary"
          title="Cancelar"
          @click="$emit('closeModal', false)"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          :title="textModal"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>{{ textModal }}</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import draggable from 'vuedraggable'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'CreateEditModeloPecaModal',
  props: {
    modeloPecaId: {
      type: String,
      default: null,
    },
  },
  components: { ckeditor: CKEditor.component, draggable },
  data() {
    return {
      modeloPeca: {
        titulo: '',
        categoria_id: '',
        briefing: '',
        tempo_estimado: '',
      },
      checklist: [],
      etapas: [],
      users: [],
      editor: ClassicEditor,
      categorias: null,
      modelosEtapasPecas: null,
      disableButton: false,
    }
  },

  computed: {
    textModal() {
      return this.modeloPecaId
        ? 'Atualizar modelo de peça'
        : 'Cadastrar modelo de peça'
    },
  },

  methods: {
    addRow() {
      this.checklist.push({
        descricao_item: '',
      })
    },

    addEtapa() {
      this.etapas.push({
        titulo: 'NOVA ETAPA [EDITAR]',
        data_final_padrão: 1,
        modelo_etapa_peca_id: 1,
      })
    },
    doDelete(index) {
      this.etapas.splice(index, 1)
    },
    deleteRow(index) {
      this.checklist.splice(index, 1)
    },

    async getModeloPeca() {
      try {
        this.modeloPeca = await this.$store.dispatch(
          'getModeloPeca',
          this.modeloPecaId,
        )
        this.checklist = await this.$store.dispatch(
          'getChecklistByModeloPeca',
          this.modeloPecaId,
        )
        this.etapas = await this.$store.dispatch(
          'getModeloEtapaPecaByModeloPeca',
          this.modeloPecaId,
        )
        this.$bvModal.show('modalCreateEditModeloPeca')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async getCategorias() {
      try {
        this.categorias = await this.$store.dispatch('getCategorias')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getUsers() {
      try {
        this.users = await this.$store.dispatch('getUsers')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getModelosEtapasPecas() {
      try {
        this.modelosEtapasPecas = await this.$store.dispatch(
          'getModelosEtapasPecas',
        )
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.modeloPecaId) {
            response = await this.$store.dispatch('updateModeloPeca', {
              modeloPecaId: this.modeloPecaId,
              modeloPeca: this.modeloPeca,
              checklist: this.checklist,
              etapas: this.etapas,
            })
          } else {
            response = await this.$store.dispatch('saveModeloPeca', {
              modeloPeca: this.modeloPeca,
              checklist: this.checklist,
              etapas: this.etapas,
            })
          }
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
          })
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    modeloPeca: {
      titulo: {
        required,
        minLength: minLength(3),
      },
      categoria_id: {
        required,
      },
    },
  },

  mounted() {
    this.getCategorias()
    this.getUsers()
    this.getModelosEtapasPecas()
    if (this.modeloPecaId) {
      this.getModeloPeca()
    } else {
      this.$bvModal.show('modalCreateEditModeloPeca')
    }
  },
}
</script>
